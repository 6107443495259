import * as React from "react";
import styled from "styled-components";
import { createGlobalStyle } from "styled-components";
import Tilt from "react-parallax-tilt";
import { device } from "../device";
import { Helmet } from "react-helmet";

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    background: #599FC7;
    font-family: 'Source Sans 3', sans-serif;
    color: #3c6176;
  }
`;

const Footer = styled.footer`
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;
  width: 100%;
  bottom: 0;
  padding-bottom: 40px;
`;
const Text = styled.h1`
  padding: 0;
  margin: 0;
  font-weight: 300;
`;

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;
  height: 100vh;
`;

const Email = styled.a`
  color: #3c6176;
  display: flex;

  &:link {
    text-decoration: none;
  }

  &:visited {
    text-decoration: none;
  }

  &:hover {
    text-decoration: underline;
    color: white;
  }
`;

const ColContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Content = styled.div`
  position: relative;
  width: 230px;
  height: 76px;

  @media ${device.mobileL} {
    width: 390px;
    height: 130px;
  }
`;

const Item = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
`;

const TextItem = ({ color, zIndex, perspective }) => {
  return (
    <Item style={{ zIndex: zIndex }}>
      <Tilt
        trackOnWindow={true}
        gyroscope={true}
        transitionSpeed="10"
        perspective={perspective}
      >
        <Text style={{color: color, zIndex: zIndex}}>"This page does not exist. But it also exists."</Text>
      </Tilt>
    </Item>
  );
};

const IndexPage = () => {
  return (
    <React.Fragment>
      <GlobalStyle />
      <Helmet>
        <meta charSet="utf-8" />
        <title>Today I'm Good</title>
        <link rel="canonical" href="https://todayimgood.com" />
      </Helmet>
      <RowContainer>
        <ColContainer>
          <Content>
            <TextItem perspective="1000" color="white" zIndex="50" />
            <TextItem perspective="1800" color="#3c6176" zIndex="30" />
          </Content>
        </ColContainer>
      </RowContainer>
      <Footer>
        <Email href="mailto:hello@TodayImGood.com">
          Reach out at: hello@TodayImGood.com
        </Email>
      </Footer>
    </React.Fragment>
  );
};

export default IndexPage;
